.early_access_notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: 1rem;
  height: 40vh;
  flex-direction: column;
  max-width: 100vw;
  background-color: #f8f9fa;
  padding: 1rem;
  display: flex;
  z-index: 1000;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  animation: comeFromBottom 0.5s ease-out;
}

@media screen and (min-width: 768px) {
  .early_access_notification {
    flex-direction: row;
  }
}

@keyframes comeFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.ant-select .ant-select-selector {
  border-radius: 20px;
}
