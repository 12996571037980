@media screen and (min-width: 768px) {
  .hoverable-journey:hover button {
    visibility: visible;
  }

  .hoverable-journey button {
    visibility: hidden;
  }

  .hoverable-journey:hover .hoverable-journey-background {
    transform: scale(1.02);
    filter: grayscale(0%) brightness(0.5);
  }
}

.hoverable-journey-background {
  transition: transform 0.6s;
  filter: grayscale(100%) brightness(0.5);
}
